import React from 'react'
import './css/GiftCertificates.css'

export default function GiftCertificates() {
  return (
    <div id="certificates" className="certificates">
      <h2>Gift Certificates</h2>
      <hr className="certificates_divider"/>
      <p>Gift Certificates are the perfect gift for any occasion.  Our gift cards can be made in any denomination and are presented beautifully in a gift bag.  Stop in anytime or you can call and order one by phone.</p>
    </div>
  )
}
