import React from 'react'
import './css/SpecialOccasion.css'
//import occasion_image from './images/occasion_image.jpg'
import makeup_image from './images/makeup_image.jpg'

export default function SpecialOccasion() {
  return (
    <div id="occasion" className="occasion">
      <h2>Special Occasion Services</h2>
      <hr className="occasion_divider"/>
      <div className="occasion-grid">
        <div className="occasion_updo">
          <h3>Updo</h3>
          <div className="occasion_updo-grid">
          <p>Our stylists specialize in gorgeous formal styles for any occasion.  Whether you prefer something classic, trendy, all up or down, we can give you the look of your dreams.  We can accomodate any size bridal party and are available before and after hours to fit your needs.</p>
          {/* <img className="occasion_image" src={occasion_image}/> */}
          </div>
        </div>

        <div className="occasion_makeup">
          <h3>Make-up</h3>
          <p>Our makeup artists use high quality hypoallergenic, oil free makeup for all skin types.  We can create natural, or full glam looks to make you look and feel your best.  We can also accommodate large parties.</p>
          {/* <img className="occasion_image" src={makeup_image}/> */}
        </div>
      </div>
    </div>
  )
}
