import React from 'react'
import './css/Services.css'
//import haircut_image_1 from './images/haircut_image_1.jpg'
//import haircut_image_2 from './images/haircut_image_2.jpg'
//import color_image from './images/color_image.jpg'
import extensions_image from './images/extensions_image.jpg'
//import conditioning_image from './images/conditioning_image.jpg'
import keratin_image from './images/keratin_image.jpg'

export default function Services() {
  return (
    <div id="services" className="services">
    <h2>Our Services</h2>
    <hr className="services_divider"/>

    <div className="services_haircut-grid">
      <div className="services_haircut-copy">
      <h3>Haircut and Styling</h3>
        <p>Whether you need a brand new look or just a trim, our stylists can give you a beautiful shape that will leave your hair full of life.  We also take great pride in delivering clean, polished blow-dry styles for long and short hair.</p>
      </div>
      <div className="services_haircut_images">
        {/* <img className="haircut_image"src={haircut_image_1}/> */}
        {/* <img className="haircut_image"src={haircut_image_2}/> */}
      </div>
    </div>

    <div className="services_color-keratin-grid">
      <div className="services_color">
        <h3>Color</h3>
        <p>We use a wide range of the best hair color products available.  Our services include highlighting, balayage, glaze, single process color, double process color, special effect coloring, and color correction.  After consulting with one of our colorists, we are sure to offer an option that is just right for you.</p>
        {/* <img className="color_image"src={color_image}/> */}
      </div>

      <div className="services_keratin">
        <h3>Brazilian Keratin treatment</h3>
        <p>Brazilian keratin treatments are designed to eliminate all frizz from your hair.  It will leave your hair feeling silky smooth, strong, shiny, and healthy.  We use organic keratin that contains 0.0% formaldehyde.  This service is safe for all hair types and lasts about 6 months.</p>
        <img src={keratin_image}/>
      </div>
    </div>

    <div className="services_extension-conditioning-grid">
    <div className="services_conditioning">
      <h3>Conditioning/Scalp Treatments</h3>
      <p>We offer many in-salon treatments to help our clients improve the condition of their hair and scalp.  We have leave-in treatments that can be applied right before your style and treatments that process under the dryer.  We also have scalp treatments for dandruff and hair loss.</p>
      {/* <img className="conditioning_image"src={conditioning_image}/> */}
    </div>
      <div className="services_extension">
        <h3>Extensions</h3>
        <img className="extensions_image"src={extensions_image}/>
        <p>Our Extensions are made of the highest quality 100% human remy hair.  We have a wide range of hair in various lengths and colors to match any hair tone. They will blend perfectly to look and feel natural.  You can use them to add length, thickness, or both. Our extensions can be washed and styled just like your natural hair.  Get longer, thicker hair to transform your look!  Consultation required.</p>
      </div>

    </div>
      <div className="services_chemical-wave-grid">
        <div className="services_chemical">
          <h3>Chemical Relaxer</h3>
          <p>Chemical Relaxer will permanently reduce your curls up to 80%.  We offer gentle, quality products that will not damage your hair.  There are different strengths available depending on the condition of your hair and how curly it is.</p>
        </div>

        <div className="services_wave">
          <h3>Permanent Wave</h3>
          <p>Permanent waves are available for anyone looking for curls or to add body to their hair.  We have a wide range of products available to fit any client's needs.</p>
          </div>
      </div>
    </div>
  )
}
