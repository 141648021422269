import beforeandafter_image_1 from './beforeandafter/33020186_253254728555505_7444191042896658432_n_17948053501017896.jpg'
import beforeandafter_image_2 from './beforeandafter/33333455_376397019431428_5722288956863676416_n_17922984748188812.jpg'
import beforeandafter_image_3 from './beforeandafter/45289433_889630371241857_7140375649016624049_n_17977215151127795.jpg'
import beforeandafter_image_4 from './beforeandafter/46937111_2062132667182223_1027058481790369342_n_17936885710230118.jpg'
import beforeandafter_image_5 from './beforeandafter/47585379_571098000019082_5788116015042971252_n_17842895677339295.jpg'
import beforeandafter_image_6 from './beforeandafter/47692567_284517185555914_1004573763090533663_n_17928790789249563.jpg'
import beforeandafter_image_7 from './beforeandafter/47693426_578591149272876_1197052001786765625_n_17990860882130671.jpg'
import beforeandafter_image_8 from './beforeandafter/47693719_2378359135724791_5838368411032790180_n_17983701874174064.jpg'
import beforeandafter_image_9 from './beforeandafter/49586012_109788996783014_834286620668751698_n_17997746221138249.jpg'
import beforeandafter_image_10 from './beforeandafter/49694975_950163108515428_6134205337519009813_n_17919574723268412.jpg'
import beforeandafter_image_11 from './beforeandafter/49933443_619323185193311_1241187828735808218_n_18002118769141723.jpg'
import beforeandafter_image_12 from './beforeandafter/53352907_658611114573417_5903502796322883588_n_18049711636023119.jpg'
import beforeandafter_image_13 from './beforeandafter/58409465_169242380738253_5459275636178422981_n_17877967606343248.jpg'
import beforeandafter_image_14 from './beforeandafter/67582818_422060405111600_2088687474655421408_n_17850616711605469.jpg'
import beforeandafter_image_15 from './beforeandafter/75362635_1528319200644664_5167374470453957133_n_17888338549427640.jpg'
import beforeandafter_image_16 from './beforeandafter/80614841_126413192176428_3425592408469004269_n_18124080298021751.jpg'
import beforeandafter_image_17 from './beforeandafter/84866967_118333326398757_5475746253841010802_n_17866195735650992.jpg'
import beforeandafter_image_18 from './beforeandafter/139368309_110996000937693_5192965225440164931_n_17978500084337313.jpg'
import beforeandafter_image_19 from './beforeandafter/183185868_214907560140373_4032963695181351918_n_17882486537321501.jpg'

import longhair_image_1 from './long_hair/29739594_653466658318349_6903776675503276032_n_17932851628069117.jpg'
import longhair_image_2 from './long_hair/30829407_174079469917878_4820932594545721344_n_17942371597057804.jpg'
import longhair_image_3 from './long_hair/31086487_134050037452016_2824545737105211392_n_17869699408235759.jpg'
import longhair_image_4 from './long_hair/31711502_2185282431496831_8672237834817503232_n_17914399498154951.jpg'
import longhair_image_5 from './long_hair/31752523_209284886338117_6354397182354784256_n_17871861109231669.jpg'
import longhair_image_6 from './long_hair/31846932_175013959987210_8446314321595596800_n_17872559281237867.jpg'
import longhair_image_7 from './long_hair/37894821_394757134383182_2711979750993166336_n_17968236208051045.jpg'
import longhair_image_8 from './long_hair/37897506_2133091036906966_606446380013060096_n_17968656367048754.jpg'
import longhair_image_9 from './long_hair/39629843_1907589782694476_6782032073696739328_n_17975098492023870.jpg'
import longhair_image_10 from './long_hair/40278804_329990347753817_7926068090368423577_n_17950036132156525.jpg'
import longhair_image_11 from './long_hair/40357114_534190783707881_8247242170814169088_n_17975156353030181.jpg'
import longhair_image_12 from './long_hair/40472151_774830339575494_2441897794241693102_n_17872331194272364.jpg'
import longhair_image_13 from './long_hair/43913183_252419115407056_3074608640913588487_n_17986649152072475.jpg'
import longhair_image_14 from './long_hair/43914669_588498271580340_1103499936687091114_n_17866617352289185.jpg'
import longhair_image_15 from './long_hair/44897284_197287167874882_3457471153426343059_n_17976675007143226.jpg'
import longhair_image_16 from './long_hair/45265641_2184272101840857_6893218941463776275_n_17970187819131077.jpg'
import longhair_image_17 from './long_hair/45931520_552215521869737_557342978865648009_n_17980967020143553.jpg'
import longhair_image_18 from './long_hair/46302541_2076844915953900_4705887438072369673_n_17981191546181626.jpg'
import longhair_image_19 from './long_hair/46882566_495685134257089_7595941826215986982_n_17951796148204014.jpg'
import longhair_image_20 from './long_hair/47105440_628414397561535_2115295959054167364_n_18010830637025123.jpg'
import longhair_image_21 from './long_hair/47582377_267681453926969_5242647020282173027_n_17938721485231129.jpg'
import longhair_image_22 from './long_hair/47582437_777957979207168_809284075966672591_n_17992744681124264.jpg'
import longhair_image_23 from './long_hair/47691451_2073701162930768_992912774757220707_n_18021194626057603.jpg'
import longhair_image_24 from './long_hair/47692318_377897926352619_3436896329755706262_n_17890462216292518.jpg'
import longhair_image_25 from './long_hair/49596469_296947477835164_1422270236830873577_n_17848138696328547.jpg'
import longhair_image_26 from './long_hair/49833652_1980349648679175_7986700700421893057_n_18000018835084106.jpg'
import longhair_image_27 from './long_hair/55886206_2036903573280746_3719780181805356010_n_18037236076115702.jpg'
import longhair_image_28 from './long_hair/56915016_2071568929559763_1508928804577541188_n_18058992247042145.jpg'
import longhair_image_29 from './long_hair/57133924_2256328331362618_1141818988263312587_n_18018735316173923.jpg'
import longhair_image_30 from './long_hair/70509693_520756898470884_5638096476557819042_n_18032711911216735.jpg'
import longhair_image_31 from './long_hair/74618214_2554996751451267_1604479585075126768_n_18121167253016282.jpg'
import longhair_image_32 from './long_hair/81446823_586474565531555_5342799367623358141_n_18079388452165016.jpg'
import longhair_image_33 from './long_hair/118389795_227273832041720_1936112255153539294_n_17896826425574534.jpg'
import longhair_image_34 from './long_hair/119115444_682598229281609_2522620837296515584_n_17872530310904845.jpg'
import longhair_image_35 from './long_hair/119234372_1438916502964210_2552221726222400083_n_17850929234309623.jpg'
import longhair_image_36 from './long_hair/132046846_180105183811418_6101290766553798511_n_17910533443564400.jpg'
import longhair_image_37 from './long_hair/189211360_1885440038304783_2578246181812844516_n_18173237740143001.jpg'
import longhair_image_38 from './long_hair/192790975_2610193089281291_8391675822012947527_n_17885574074260851.jpg'
import longhair_image_39 from './long_hair/195584086_520097966004878_3575449989526988426_n_18116813680230152.jpg'
import longhair_image_40 from './long_hair/261152314_1503696593346355_6882697832487440500_n_18029792044320872.jpg'

import shorthair_image_1 from './short hair/27892488_534351746935926_7832948530225348608_n_17894956312174306.jpg'
import shorthair_image_2 from './short hair/29716609_912183648988479_4988971897216565248_n_17936043895018624.jpg'
import shorthair_image_3 from './short hair/29737037_157045464987725_2243625150891163648_n_17936153323050760.jpg'
import shorthair_image_4 from './short hair/30590235_196006034459151_4257289356093423616_n_17879778187204767.jpg'
import shorthair_image_5 from './short hair/37368375_495971520847371_4425888434736857088_n_17856139675275782.jpg'
import shorthair_image_6 from './short hair/37812754_997413190428600_4016122711880237056_n_17847518173282126.jpg'
import shorthair_image_7 from './short hair/38493366_218213665533872_6221703819852513280_n_17946946645083987.jpg'
import shorthair_image_8 from './short hair/45967354_443552936175887_7594332430236719781_n_18006954874017145.jpg'
import shorthair_image_9 from './short hair/46412204_354298261816462_2730720515300695429_n_17996828794116851.jpg'
import shorthair_image_10 from './short hair/46650644_297967267499543_1490365777762739727_n_17916621622250537.jpg'
import shorthair_image_11 from './short hair/47692817_142701876723374_8035956602321864230_n_17930566222249920.jpg'
import shorthair_image_12 from './short hair/51939611_1512266575570587_7087444216773713258_n_17978047579204882.jpg'
import shorthair_image_13 from './short hair/65866705_358932321689192_450288509872379986_n_17974852780274579.jpg'

import occasion_image_1 from './special occasion/12907211_437422483095151_155769077_n_17846759476108794.jpg'
import occasion_image_2 from './special occasion/12940831_601144836702673_1748665102_n_17855963713060355.jpg'
import occasion_image_3 from './special occasion/12950414_938692002918324_1671460045_n_17846632513125292.jpg'
import occasion_image_4 from './special occasion/12976465_1683784518538084_1463188844_n_17846637307109698.jpg'
import occasion_image_5 from './special occasion/26186708_1844899608855890_7637749447807467520_n_17924468611017484.jpg'
import occasion_image_6 from './special occasion/27879514_2053113448303162_176971498911694848_n_17926239457030093.jpg'
import occasion_image_7 from './special occasion/27880292_326917567830469_5858063988465598464_n_17912461615116492.jpg'
import occasion_image_8 from './special occasion/28433636_152491025433127_1036658853028036608_n_17917480990128285.jpg'
import occasion_image_9 from './special occasion/29715764_1772932262770893_5394380977046814720_n_17923076917110488.jpg'
import occasion_image_10 from './special occasion/29736960_163043951181772_994001469772398592_n_17936461609060556.jpg'
import occasion_image_11 from './special occasion/29737329_348498585638099_7000366998181904384_n_17897066653197595.jpg'
import occasion_image_12 from './special occasion/30078714_241030146462403_989213586259705856_n_17910020872144033.jpg'
import occasion_image_13 from './special occasion/30079051_416449418820182_1647835349243658240_n_17845308241261027.jpg'
import occasion_image_14 from './special occasion/31326468_1974458832566909_763939377603674112_n_17944611046017480.jpg'
import occasion_image_15 from './special occasion/31936452_201061623844104_4848914216284848128_n_17884891141208578.jpg'
import occasion_image_16 from './special occasion/44405917_1939838486322208_7868617020661517710_n_17922633478243486.jpg'
import occasion_image_17 from './special occasion/49802191_518113388676692_8929539097272429369_n_18005924827100765.jpg'
import occasion_image_18 from './special occasion/52147161_367682807418723_2025614017381983894_n_18044352997000347.jpg'
import occasion_image_19 from './special occasion/59502561_605965916571815_1994125788858616706_n_17877168325361756.jpg'
import occasion_image_20 from './special occasion/61845533_468634493941616_4980995486170252928_n_17889552751356404.jpg'
import occasion_image_21 from './special occasion/62380913_318654059060579_1411068722771237673_n_17874071476390672.jpg'
import occasion_image_22 from './special occasion/65046932_2237737149608080_1784974217054471075_n_17853803770468942.jpg'
import occasion_image_23 from './special occasion/66384060_476805513095898_1929343249749381615_n_17843568592523798.jpg'
import occasion_image_24 from './special occasion/82441565_843928469411447_5548239346475569814_n_17873231044554207.jpg'

export const beforeandafter_images = [
  {
    original: `${beforeandafter_image_1}`,
    thumbnail: `${beforeandafter_image_1}`
  },
  {
    original: `${beforeandafter_image_2}`,
    thumbnail: `${beforeandafter_image_2}`
  },
  {
    original: `${beforeandafter_image_3}`,
    thumbnail: `${beforeandafter_image_3}`
  },
  {
    original: `${beforeandafter_image_4}`,
    thumbnail: `${beforeandafter_image_4}`
  },
  {
    original: `${beforeandafter_image_5}`,
    thumbnail: `${beforeandafter_image_5}`
  },
  {
    original: `${beforeandafter_image_6}`,
    thumbnail: `${beforeandafter_image_6}`
  },
  {
    original: `${beforeandafter_image_7}`,
    thumbnail: `${beforeandafter_image_7}`
  },
  {
    original: `${beforeandafter_image_8}`,
    thumbnail: `${beforeandafter_image_8}`
  },
  {
    original: `${beforeandafter_image_9}`,
    thumbnail: `${beforeandafter_image_9}`
  },
  {
    original: `${beforeandafter_image_10}`,
    thumbnail: `${beforeandafter_image_10}`
  },
  {
    original: `${beforeandafter_image_11}`,
    thumbnail: `${beforeandafter_image_11}`
  },
  {
    original: `${beforeandafter_image_12}`,
    thumbnail: `${beforeandafter_image_12}`
  },
  {
    original: `${beforeandafter_image_13}`,
    thumbnail: `${beforeandafter_image_13}`
  },
  {
    original: `${beforeandafter_image_14}`,
    thumbnail: `${beforeandafter_image_14}`
  },
  {
    original: `${beforeandafter_image_15}`,
    thumbnail: `${beforeandafter_image_15}`
  },
  {
    original: `${beforeandafter_image_16}`,
    thumbnail: `${beforeandafter_image_16}`
  },
  {
    original: `${beforeandafter_image_17}`,
    thumbnail: `${beforeandafter_image_17}`
  },
  {
    original: `${beforeandafter_image_18}`,
    thumbnail: `${beforeandafter_image_18}`
  },
  {
    original: `${beforeandafter_image_19}`,
    thumbnail: `${beforeandafter_image_19}`
  },
];

export const long_hair_images = [
  {
    original: `${longhair_image_1}`,
    thumbnail: `${longhair_image_1}`,
  },
  {
    original: `${longhair_image_2}`,
    thumbnail: `${longhair_image_2}`,
  },
  {
    original: `${longhair_image_3}`,
    thumbnail: `${longhair_image_3}`,
  },
  {
    original: `${longhair_image_4}`,
    thumbnail: `${longhair_image_4}`,
  },
  {
    original: `${longhair_image_5}`,
    thumbnail: `${longhair_image_5}`,
  },
  {
    original: `${longhair_image_6}`,
    thumbnail: `${longhair_image_6}`,
  },
  {
    original: `${longhair_image_7}`,
    thumbnail: `${longhair_image_7}`,
  },
  {
    original: `${longhair_image_8}`,
    thumbnail: `${longhair_image_8}`,
  },
  {
    original: `${longhair_image_9}`,
    thumbnail: `${longhair_image_9}`,
  },
  {
    original: `${longhair_image_10}`,
    thumbnail: `${longhair_image_10}`,
  },
  {
    original: `${longhair_image_11}`,
    thumbnail: `${longhair_image_11}`,
  },
  {
    original: `${longhair_image_12}`,
    thumbnail: `${longhair_image_12}`,
  },
  {
    original: `${longhair_image_13}`,
    thumbnail: `${longhair_image_13}`,
  },
  {
    original: `${longhair_image_14}`,
    thumbnail: `${longhair_image_14}`,
  },
  {
    original: `${longhair_image_15}`,
    thumbnail: `${longhair_image_15}`,
  },
  {
    original: `${longhair_image_16}`,
    thumbnail: `${longhair_image_16}`,
  },
  {
    original: `${longhair_image_17}`,
    thumbnail: `${longhair_image_17}`,
  },
  {
    original: `${longhair_image_18}`,
    thumbnail: `${longhair_image_18}`,
  },
  {
    original: `${longhair_image_19}`,
    thumbnail: `${longhair_image_19}`,
  },
  {
    original: `${longhair_image_20}`,
    thumbnail: `${longhair_image_20}`,
  },
  {
    original: `${longhair_image_21}`,
    thumbnail: `${longhair_image_21}`,
  },
  {
    original: `${longhair_image_22}`,
    thumbnail: `${longhair_image_22}`,
  },
  {
    original: `${longhair_image_23}`,
    thumbnail: `${longhair_image_23}`,
  },
  {
    original: `${longhair_image_24}`,
    thumbnail: `${longhair_image_24}`,
  },
  {
    original: `${longhair_image_25}`,
    thumbnail: `${longhair_image_25}`,
  },
  {
    original: `${longhair_image_26}`,
    thumbnail: `${longhair_image_26}`,
  },
  {
    original: `${longhair_image_27}`,
    thumbnail: `${longhair_image_27}`,
  },
  {
    original: `${longhair_image_28}`,
    thumbnail: `${longhair_image_28}`,
  },
  {
    original: `${longhair_image_29}`,
    thumbnail: `${longhair_image_29}`,
  },
  {
    original: `${longhair_image_30}`,
    thumbnail: `${longhair_image_30}`,
  },
  {
    original: `${longhair_image_31}`,
    thumbnail: `${longhair_image_31}`,
  },
  {
    original: `${longhair_image_32}`,
    thumbnail: `${longhair_image_32}`,
  },
  {
    original: `${longhair_image_33}`,
    thumbnail: `${longhair_image_33}`,
  },
  {
    original: `${longhair_image_34}`,
    thumbnail: `${longhair_image_34}`,
  },
  {
    original: `${longhair_image_35}`,
    thumbnail: `${longhair_image_35}`,
  },
  {
    original: `${longhair_image_36}`,
    thumbnail: `${longhair_image_36}`,
  },
  {
    original: `${longhair_image_37}`,
    thumbnail: `${longhair_image_37}`,
  },
  {
    original: `${longhair_image_38}`,
    thumbnail: `${longhair_image_38}`,
  },
  {
    original: `${longhair_image_39}`,
    thumbnail: `${longhair_image_39}`,
  },
  {
    original: `${longhair_image_40}`,
    thumbnail: `${longhair_image_40}`,
  },
];

export const short_hair_images = [
  {
    original: `${shorthair_image_1}`,
    thumbnail: `${shorthair_image_1}`
  },
  {
    original: `${shorthair_image_2}`,
    thumbnail: `${shorthair_image_2}`
  },
  {
    original: `${shorthair_image_3}`,
    thumbnail: `${shorthair_image_3}`
  },
  {
    original: `${shorthair_image_4}`,
    thumbnail: `${shorthair_image_4}`
  },
  {
    original: `${shorthair_image_5}`,
    thumbnail: `${shorthair_image_5}`
  },
  {
    original: `${shorthair_image_6}`,
    thumbnail: `${shorthair_image_6}`
  },
  {
    original: `${shorthair_image_7}`,
    thumbnail: `${shorthair_image_7}`
  },
  {
    original: `${shorthair_image_8}`,
    thumbnail: `${shorthair_image_8}`
  },
  {
    original: `${shorthair_image_9}`,
    thumbnail: `${shorthair_image_9}`
  },
  {
    original: `${shorthair_image_10}`,
    thumbnail: `${shorthair_image_10}`
  },
  {
    original: `${shorthair_image_11}`,
    thumbnail: `${shorthair_image_11}`
  },
  {
    original: `${shorthair_image_12}`,
    thumbnail: `${shorthair_image_12}`
  },
  {
    original: `${shorthair_image_13}`,
    thumbnail: `${shorthair_image_13}`
  },
];

export const occasion_images = [
  {
    original: `${occasion_image_1}`,
    thumbnail: `${occasion_image_1}`,
  },
  {
    original: `${occasion_image_2}`,
    thumbnail: `${occasion_image_2}`,
  },
  {
    original: `${occasion_image_3}`,
    thumbnail: `${occasion_image_3}`,
  },
  {
    original: `${occasion_image_4}`,
    thumbnail: `${occasion_image_4}`,
  },
  {
    original: `${occasion_image_5}`,
    thumbnail: `${occasion_image_5}`,
  },
  {
    original: `${occasion_image_6}`,
    thumbnail: `${occasion_image_6}`,
  },
  {
    original: `${occasion_image_7}`,
    thumbnail: `${occasion_image_7}`,
  },
  {
    original: `${occasion_image_8}`,
    thumbnail: `${occasion_image_8}`,
  },
  {
    original: `${occasion_image_9}`,
    thumbnail: `${occasion_image_9}`,
  },
  {
    original: `${occasion_image_10}`,
    thumbnail: `${occasion_image_10}`,
  },
  {
    original: `${occasion_image_11}`,
    thumbnail: `${occasion_image_11}`,
  },
  {
    original: `${occasion_image_12}`,
    thumbnail: `${occasion_image_12}`,
  },
  {
    original: `${occasion_image_13}`,
    thumbnail: `${occasion_image_13}`,
  },
  {
    original: `${occasion_image_14}`,
    thumbnail: `${occasion_image_14}`,
  },
  {
    original: `${occasion_image_15}`,
    thumbnail: `${occasion_image_15}`,
  },
  {
    original: `${occasion_image_16}`,
    thumbnail: `${occasion_image_16}`,
  },
  {
    original: `${occasion_image_17}`,
    thumbnail: `${occasion_image_17}`,
  },
  {
    original: `${occasion_image_18}`,
    thumbnail: `${occasion_image_18}`,
  },
  {
    original: `${occasion_image_19}`,
    thumbnail: `${occasion_image_19}`,
  },
  {
    original: `${occasion_image_20}`,
    thumbnail: `${occasion_image_20}`,
  },
  {
    original: `${occasion_image_21}`,
    thumbnail: `${occasion_image_21}`,
  },
  {
    original: `${occasion_image_22}`,
    thumbnail: `${occasion_image_22}`,
  },
  {
    original: `${occasion_image_23}`,
    thumbnail: `${occasion_image_23}`,
  },
  {
    original: `${occasion_image_24}`,
    thumbnail: `${occasion_image_24}`,
  },
]
