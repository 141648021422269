import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import './css/Nav.css'

export default function Nav() {
  const [isMobile, setIsMobile] = useState(false);

  return (
    <nav className="site-nav">
      <a href="tel:5163527237"><i className="fas fa-phone"></i></a>
      <ul className={isMobile ? "nav-links-mobile" : "nav-links"}
       >
        <a href="/"> <li>Home</li></a>
        <a href="/gallery"><li>Gallery</li></a>
      </ul>
      <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
        {isMobile ? (
          <i className="fas fa-times"></i>
        ) : (
          <i className="fas fa-bars"></i>
        )}
      </button>
    </nav>
  )
}
