import React, { useState } from 'react'
import ImageGallery from 'react-image-gallery';
import {beforeandafter_images} from './gallery/gallery'
import {long_hair_images} from './gallery/gallery'
import {short_hair_images} from './gallery/gallery'
import {occasion_images} from './gallery/gallery'
//import video from './images/palace.mp4'
import './css/Gallery.css'

export default function Gallery() {


  return (
    <div>
      <h1 className="gallery-header">Gallery</h1>
      <div className="galleries">
        <div className="gallery long-hair-gallery">
          <h2>Long Hair</h2>
          <hr className="gallery-divider"/>
          <ImageGallery thumbnailPosition='right' showIndex='true'
          items={long_hair_images}/>
        </div>

        <div className="gallery short-hair-gallery">
          <h2>Short Hair</h2>
          <hr className="gallery-divider"/>
          <ImageGallery thumbnailPosition='right' showIndex='true'
          items={short_hair_images}/>
        </div>

        <div className="gallery occasion-gallery">
          <h2>Special Occasion</h2>
          <hr className="gallery-divider"/>
          <ImageGallery thumbnailPosition='right' showIndex='true'
          items={occasion_images}/>
        </div>

        <div className="gallery beforeandafter-gallery">
          <h2>Before and After</h2>
          <hr className="gallery-divider"/>
          <ImageGallery thumbnailPosition='right' showIndex='true'
          items={beforeandafter_images}/>
        </div>

      </div>
    </div>
  )
}
