import React from 'react'
import About from './About'
import Services from './Services'
import SpecialOccasion from './SpecialOccasion'
import GiftCertificates from './GiftCertificates'
import Microblading from './Microblading'
import Hero from './Hero'

export default function Home() {
  return (
    <div>
      <Hero />
      <About />
      <Services />
      <SpecialOccasion />
      <Microblading />
      <GiftCertificates />
    </div>
  )
}
