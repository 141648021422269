import React from 'react'
import './css/Footer.css'

export default function Footer() {
  return (
    <div id="footer" className="footer">
      <p className="footer_header">Palace Hair Salon</p>

      <div className="footer_contact">
        <p className="footer_address">1052 Hempstead Turnpike, Franklin Square, NY 11010</p>
        <p className="footer_phone">516.352.7237</p>
      </div>
      <div className="footer_hours">
        <p>Monday - CLOSED (available for parties or special occasions)</p>
        <p>Tuesday - 9:00am - 6:00pm</p>
        <p>Wednesday - 9:00am - 6:00pm</p>
        <p>Thursday - 9:00am - 6:00pm</p>
        <p>Friday - 9:00am - 6:00pm</p>
        <p>Saturday - 8:00am - 5:00pm</p>
        <p>Sunday - 8:00am - 3:30pm</p>
      </div>
      <div className="footer_bottom">
        <div className="footer_social">
          <a className="social-link" href="https://www.facebook.com/palacehairsalon" target="_blank"><i className="fab fa-facebook"></i></a>
          <a className="social-link" href="https://www.instagram.com/palacehairsalon/" target="_blank"><i className="fab fa-instagram"></i></a>
        </div>
        <p className="footer_copyright">&copy; Palace Hair Salon</p>
      </div>
    </div>
  )
}
