import React from 'react'
import "./css/Hero.css"
//import image from './images/hero_image_3_scaled.png'

export default function Hero() {

  return (
    <div className="hero">
      {/* <img className="hero-image" src={image}/> */}
    </div>
  )
}
