import './App.css';
import Home from './Home'
import Nav from './Nav'
import Gallery from './Gallery'
import Footer from './Footer'
import {BrowserRouter as Router, Routes, Route, Switch} from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Nav />
      <Router>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/gallery" element={<Gallery/>} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
