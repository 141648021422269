import React from 'react';
import './css/About.css';
//import video from './images/palace.mp4'

export default function About() {
  return (
    <div id="about" className="about">
      <h2>Welcome to Palace Hair Salon</h2>
      <p>45 years of excellence! Palace Hair Salon is family owned and operated with a talented staff of hair specialists. Our atmosphere is friendly and welcoming to all. Stop by or call for an appointment today.</p>
      {/* <div className="palace-video">
        <video controls>
          <source src={video} type="video/mp4"/>
        </video>
      </div> */}
    </div>
  )
}
