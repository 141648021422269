import React from 'react'
import './css/Microblading.css'
import microblading_image_1 from './images/microblading_image_1.jpg'

export default function Microblading() {
  return (
    <div className="microblading">
      <h2>Microblading</h2>
      <hr className="microblading_divider"/>
      <div className="microblading_grid">
        <img className="microblading_image" src={microblading_image_1}/>
        <div className="microblading_copy">
          <p>Microblading is an eyebrow shaping technique.  Semi-permanent, surface layer tattooing is used to create fuller eyebrow looks.  It is an ideal service for clients who have thin, uneven or patchy eyebrows.  We use medical grade, organic pigment that matches your natural eyebrow color perfectly. The super fine needle implants tiny, individual hair strokes that create fine, realistic and more natural looks than traditional eyebrow tattoos.  The hair strokes will be drawn on by hand and blend  right in with your natural brows.  We will customize a shape and look that will compliment you and make you feel amazing!</p>
          <h3>MAINTENANCE</h3>
          <p>After your initial visit, you will come back in 4 weeks for a followup visit.  At the followup visit we will fill in any spaces and ensure there are no areas tha faded during the healing process.  Your brows will last about 3 years</p>
        </div>
        <div className="microblading_instagram">
          <a className="social-link" href="https://instagram.com/microblading_by_galina?utm_medium=copy_link" target="_blank"><i className="fab fa-instagram"></i>Microblading_by_galina</a>
        </div>
      </div>
    </div>
  )
}
